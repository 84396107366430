import NotFound from "../components/NotFound";

function Custom404() {
  return (
    <div className="section-box shop-template mt-30">
      <div className="container">
        <NotFound />
      </div>
    </div>
  );
}

export default Custom404;
