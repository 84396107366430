import { useSelector } from "react-redux";
import Language from "../../locales/GetLanguageResourceResponse.json";
import Link from "next/link";
import Logo from "../../public/static/imgs/template/footer-logo.png";
import Image from "next/image";
const NotFound = () => {
  const languageStore = useSelector((state) => state.language);

  return (
    <>
      <div className="ptt-not-found">
        <div className="text-center mb-150 mt-50">
          <div className="mb-20">
            {" "}
            <Image
              className=""
              style={{ objectFit: "contain" }}
              height={80}
              src={Logo}
              alt="Ptt Filateli"
            />
          </div>

          <h3 className="font-lg mb-5 ptt-not-found-color">
            {Language[languageStore].notFoundTitle}
          </h3>
          <p className="font-md ptt-not-found-color">
            {Language[languageStore].notFoundSlogan}
          </p>
          <div className="mt-15">
            {" "}
            <Link href="/" className="btn btn-pttFilateli w-auto">
              {" "}
              {Language[languageStore].notFoundBtnTitle}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
